import React from "react"
import { Section } from "src/utils"
import { Slider } from "src/components/slider/Slider"
import { Carousel } from "src/components/carousel/Carousel"
import { Instalment } from "../types"
import { ExampleOfInterest } from "src/components/example-of-interest/ExampleOfInterest"
import { RepresentativeExample } from "src/components/representative-example/RepresentativeExample"
import { getLoanRates, LoanRates } from "src/lib/lendify/lendify.service"

import { useRouter } from "next/router"

import { useDebouncedValue } from "rooks"
import { effectiveRateCalculation, instalmentsGenerator } from "src/lib/loanInterestHandler"
import {  numberWithDecimal, numberWithThousandSeparator } from "src/lib/numbers"

import s from "./LoanModule.module.scss"
import cn from "classnames"
import { Button } from "src/components/button/Button"

import InfoModal from "src/components/info-modal/InfoModal"
import { useBus } from "react-bus"
import { Container } from "src/layout/container/Container"
import { SanityRichText } from "src/sanity/types"
import Splash from "src/components/splash/Splash"

/** To divide to smaller coponents */
interface LoanModuleProps {
    loanModuleTitle: string
    howMuchWillYouBorrowSubtitle: string
    howMuchWillYouPayOff: string
    periodInformation: string
    minLoanValue: number
    maxLoanValue: number
    yearsText: string
    minLoanPeriod: number
    maxLoanPeriod: number
    currency: string
    originationFeeRebate?: number
    originationFeeRebateLabel? :string
    originationFeeRebateButtonLabel?:string
    originationFeeRebateModalTitle?:string
    originationFeeRebateModalContent?:SanityRichText
    exampleInterestRateTitle: string
    rateOfAnnualInterestRebate?: number
    rateOfAnnualInterestRebateLabel?: string
    rateOfAnnualInterestRebateButtonLabel?: string
    rateOfAnnualInterestRebateModalTitle?: string
    rateOfAnnualInterestRebateModalContent?: SanityRichText
    effectiveExampleInterestrate: string
    longestInterestRateTitle: string
    minLongestInterestRate: number
    rewardButton: string
    rewardsModalTitle?: string
    rewardsModal: SanityRichText
    exampleOfInterestTitle: string
    applyForLoan: string
    basicReuqirements: string
    basicReuqirementsModalTitle?: string
    basicReuqirementsModal: SanityRichText
    interestRateDetermined: string
    interestRateDeterminedModalTitle?: string
    interestRateDeterminedModal: SanityRichText
    representativeExampleContent: SanityRichText
}

export default function LoanModule({
    loanModuleTitle,
    howMuchWillYouBorrowSubtitle,
    howMuchWillYouPayOff,
    periodInformation,
    minLongestInterestRate,
    minLoanValue,
    maxLoanValue,
    yearsText,
    minLoanPeriod,
    maxLoanPeriod,
    currency,
    originationFeeRebate,
    originationFeeRebateLabel,
    originationFeeRebateButtonLabel,
    originationFeeRebateModalTitle,
    originationFeeRebateModalContent,
    exampleInterestRateTitle,
    rateOfAnnualInterestRebate,
    rateOfAnnualInterestRebateLabel,
    rateOfAnnualInterestRebateButtonLabel,
    rateOfAnnualInterestRebateModalTitle,
    rateOfAnnualInterestRebateModalContent,
    longestInterestRateTitle,
    effectiveExampleInterestrate,
    rewardButton,
    rewardsModalTitle,
    rewardsModal,
    exampleOfInterestTitle,
    applyForLoan,
    basicReuqirements,
    basicReuqirementsModalTitle,
    basicReuqirementsModal,
    interestRateDetermined,
    interestRateDeterminedModalTitle,
    interestRateDeterminedModal,
    representativeExampleContent,
}: LoanModuleProps) {
    const [amountToLoan, setAmountToLoan] = React.useState(maxLoanValue / 2)
    const [selectedInstalment, setSelectedInstalment] = React.useState(Math.round(maxLoanPeriod / 2))
    const [currentInstalments, setCurrentInstalments] = React.useState<Instalment[]>([])
    const [loanRates, setLoanRates] = React.useState<LoanRates>()

    const [debouncedamountToLoan] = useDebouncedValue(amountToLoan, 500)

    const bus = useBus()
    const { push, asPath, locale } = useRouter()

    const redirecthandler = React.useCallback(() => {
        /** Temporary hardcoded - to check if client wants to set this url */
        push(`https://se.lunar.app/lana-pengar-specifikation?amt=${amountToLoan}&ten=${selectedInstalment + 1}&redirect=${asPath.slice(1, asPath.length)}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amountToLoan, selectedInstalment])

    React.useEffect(() => {
        getLoanRates()
            .then(setLoanRates)
            // eslint-disable-next-line no-console
            .catch((error) => console.error("Unable to retrieve loan rates", error))
    }, [])

    React.useEffect(() => {
        if (loanRates) {
            const instalmentData = {
                minLoanPeriod,
                maxLoanPeriod,
                amountToLoan: debouncedamountToLoan,
                loanRates,
                currency,
                periodInformation,
                originationFeeRebate,
                rateOfAnnualInterestRebate,
            }
            const instalments = instalmentsGenerator(instalmentData)

            setCurrentInstalments(instalments)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedamountToLoan, loanRates])

    const effectiveRateForSelectedInstalment = React.useMemo(() =>  Array.isArray(currentInstalments) && currentInstalments.length > 0 ? effectiveRateCalculation(
        currentInstalments[selectedInstalment]?.instalmentAmount,
        debouncedamountToLoan,
        currentInstalments[selectedInstalment]?.numberOfYears * 12) : null,
    [currentInstalments, debouncedamountToLoan, selectedInstalment])

    return (
        <>
            <Section>
                <Container>
                    <div className={s["loan-module"]}>
                        <h2 className={s["loan-module__main-title"]}>{loanModuleTitle}</h2>
                        <div className={s["loan-module__container"]}>
                            <section className={s["loan-module__section"]}>
                                <h3 className={s["loan-module__title"]}>{howMuchWillYouBorrowSubtitle}</h3>
                                <p className={s["loan-module__main-info"]}>{numberWithThousandSeparator(amountToLoan)}{" "}{currency}</p>
                                <Slider
                                    className={s["loan-module__slider"]}
                                    min={minLoanValue}
                                    max={maxLoanValue}
                                    value={amountToLoan}
                                    onChange={setAmountToLoan}
                                    step={5000}
                                />
                                <p className={s["loan-module__title"]}>{howMuchWillYouPayOff}</p>
                                <Carousel
                                    className={s["loan-module__carousel"]}
                                    selectedInstalment={selectedInstalment}
                                    setSelectedInstalment={setSelectedInstalment}
                                    elements={currentInstalments}
                                    yearsText={yearsText}
                                />
                                <Button onClick={() => bus.emit("basicReuqirements")} className={s["loan-module__modal-opener"]}>{basicReuqirements}</Button>
                                <Button onClick={() => redirecthandler()} className={cn(s["loan-module__redirect"], "button--pill", s["loan-module__redirect--desktop"])}>{applyForLoan}</Button>
                            </section>
                            <div className={s["loan-module__divider"]}></div>
                            <aside className={s["loan-module__aside"]}>

                                <ExampleOfInterest
                                    title={exampleOfInterestTitle}
                                    effectiveExampleInterestrate={effectiveExampleInterestrate}
                                    exampleInterestRateTitle={exampleInterestRateTitle}
                                    rateOfAnnualInterestRebate={rateOfAnnualInterestRebate}
                                    longestInterestRateTitle={longestInterestRateTitle}
                                    rewardButton={rewardButton}
                                    minLongestInterestRate={minLongestInterestRate}
                                    rateOfAnnualInterest={loanRates?.rates[`year${selectedInstalment + 1}`].rate}
                                    effectiveRateForSelectedInstalment={effectiveRateForSelectedInstalment}
                                    discount={loanRates?.rates[`year${selectedInstalment + 1}`].discount}
                                />

                                {typeof rateOfAnnualInterestRebate === "number" && rateOfAnnualInterestRebate > 0 && (
                                    <>
                                        <Splash>
                                            <p><span className="label-250">{numberWithDecimal(rateOfAnnualInterestRebate, locale)}%</span> { rateOfAnnualInterestRebateLabel }</p>
                                            <button onClick={() => bus.emit("rateOfAnnualInterestRebate")} className="label-200">{ rateOfAnnualInterestRebateButtonLabel }</button>
                                        </Splash>
                                        <InfoModal modalVersion event="rateOfAnnualInterestRebate" body={rateOfAnnualInterestRebateModalContent} title={rateOfAnnualInterestRebateModalTitle} />
                                    </>
                                )}

                                { typeof originationFeeRebate === "number" &&  originationFeeRebate > 0 && (
                                    <>
                                        <Splash>
                                            <p><span className="label-250">{[numberWithThousandSeparator(originationFeeRebate), currency].join(" ")}</span> {  originationFeeRebateLabel }</p>
                                            <button onClick={() => bus.emit(" originationFeeRebate")} className="label-200">{  originationFeeRebateButtonLabel }</button>
                                        </Splash>
                                        <InfoModal modalVersion event=" originationFeeRebate" body={ originationFeeRebateModalContent} title={ originationFeeRebateModalTitle} />
                                    </>
                                )}
                                <Button onClick={() => bus.emit("interestRateDetermined")} className={s["loan-module__modal-opener"]}>{interestRateDetermined}</Button>
                                <Button onClick={() => redirecthandler()} className={cn(s["loan-module__redirect"], "button--pill", s["loan-module__redirect--mobile"])}>{applyForLoan}</Button>
                                <RepresentativeExample
                                    representativeExampleContent={representativeExampleContent}
                                />
                            </aside>
                        </div>
                    </div>
                </Container>
            </Section>
            <InfoModal modalVersion event="interestRateDetermined" body={interestRateDeterminedModal} title={interestRateDeterminedModalTitle} />
            <InfoModal modalVersion event="basicReuqirements" body={basicReuqirementsModal} title={basicReuqirementsModalTitle} />
            <InfoModal modalVersion event="rewards" body={rewardsModal} title={rewardsModalTitle} />
        </>
    )
}
